import React, { useState, memo, lazy, Suspense, useEffect, useRef, createRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import axios from 'axios';
import HttpStatus from 'http-status-codes';
import ReCAPTCHA from 'react-google-recaptcha';
import validationSchema from './validationSchema';
import { SttExpansionPanel, SttDivider, SttContainer, SttButton, SttLoading, SttAlerta, SttHeading, SttCircularProgress } from '@stt-componentes/core';
import Functions from '@common/Functions';
import Configs from '@constantes';
import { IDENTIFICAO } from '@componentes/solicitacao/identificacao/form/fieldNames';
import { COMPLEMENTO, DATA_NASCIMENTO } from '@componentes/solicitacao/complemento/form/fieldNames';
import { DADOS_PROFISSIONAIS, NUMERO_CONSELHO } from '@componentes/solicitacao/profissao/form/fieldNames';
import { VINCULO } from '@componentes/solicitacao/vinculo/fieldNames';
import translate from '@componentes/translate';
import { AUTORIZACAO } from '@src/componentes/solicitacao/termo-autorizacao/fieldNames';
import { CONTATO, EMAIL } from '@componentes/solicitacao/contato/form/fieldNames';
import ModalEscolhaUfCidade from '@src/componentes/solicitacao/modal-escolha-uf-cidade';
import SttSenha from '@stt-componentes/senha';

const Identificacao = lazy(() => import('@componentes/solicitacao/identificacao'));
const Complemento = lazy(() => import('@componentes/solicitacao/complemento'));
const Contato = lazy(() => import('@componentes/solicitacao/contato'));
const Profissao = lazy(() => import('@componentes/solicitacao/profissao'));
const Participacao = lazy(() => import('@componentes/solicitacao/participacao'));
const Vinculo = lazy(() => import('@componentes/solicitacao/vinculo'));
const TermoAutorizacao = lazy(() => import('@componentes/solicitacao/termo-autorizacao'));

const useStyles = makeStyles((theme) => ({
    buttonWrapper: {
        marginTop: theme.spacing(1),
    },
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%',
    },
    header: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
    },
    button: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    expansionPanel: {
        width: '100%',
    },
}));

const Divider = memo((props) => {
    return <SttDivider {...props} />;
});

const Alerta = memo((props) => {
    return <SttAlerta {...props} />;
});

const Solicitacao = ({ dadosSolicitacao, cpf, strings, dadosCadsusCnes }) => {
    const classes = useStyles();
    const schema = validationSchema(strings);
    const recaptchaRef = createRef();
    const ref = useRef();

    const [initialValues, setInitalValues] = useState(null);

    // Escolha de UF e cidade pelo usuário
    const [mostrarModalEscolhaUfCidade, setMostrarModalEscolhaUfCidade] = useState(false);
    const [callbackEscolhaUfCidade, setCallbackEscolhaUfCidade] = useState(null);

    // Escolha de usuário e senha
    const [dadosVerificacaoSenha, setDadosVerificacaoSenha] = useState({});
    const [mostrarModalEscolhaUsuarioSenha, setMostrarModalEscolhaUsuarioSenha] = useState(false);
    const [callbackEscolhaUsuarioSenha, setCallbackEscolhaUsuarioSenha] = useState(null);


    const handleCloseAlerta = () => {
        setMostrarAlerta(false);
    };

    useEffect(() => {
        if (dadosSolicitacao) {
            setInitalValues(Functions.formatarParaSolicitacao(cpf, dadosSolicitacao));
        }
    }, [dadosSolicitacao]);

    //Alerta
    const [mostrarAlerta, setMostrarAlerta] = useState(false);
    const [tituloAlerta, setTituloAlerta] = useState('');
    const [tipoAlerta, setTipoAlerta] = useState('alert');
    const [mensagemAlerta, setMensagemAlerta] = useState('');
    const [onCloseAlerta, setOnCloseAlerta] = useState(() => handleCloseAlerta);
    const [opcoesAlerta, setOpcoesAlerta] = useState([
        {
            title: strings.ok,
            onClick: handleCloseAlerta,
        },
    ]);

    const secaoIdentificacao = useRef(null);
    const secaoComplemento = useRef(null);
    const secaoContato = useRef(null);
    const secaoProfissao = useRef(null);
    const secaoVinculo = useRef(null);
    const secaoTermo = useRef(null);

    const scrollTela = (errors) => {
        if (!errors) {
            return;
        }

        const blocos = [
            { id: IDENTIFICAO, ref: secaoIdentificacao.current },
            { id: COMPLEMENTO, ref: secaoComplemento.current },
            { id: CONTATO, ref: secaoContato.current },
            { id: DADOS_PROFISSIONAIS, ref: secaoProfissao.current },
            { id: VINCULO, ref: secaoVinculo.current },
            { id: AUTORIZACAO, ref: secaoTermo.current },
        ];

        const bloco = blocos.find((b) => errors[b.id]);

        if (bloco && bloco.id === VINCULO) {
            setMostrarAlerta(true);
            setTituloAlerta(strings.atencao);
            setTipoAlerta('alert');
            setMensagemAlerta(strings.instituicaoObrigatoria);
            setOpcoesAlerta([
                {
                    title: strings.ok,
                    onClick: handleCloseAlerta,
                },
            ]);
        }

        if (bloco) {
            setTimeout(() => {
                bloco.ref.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'start',
                });
            }, 200);
        }
    };

    const opcaoVoltarPreencherVinculo = () => {
        setMostrarAlerta(false);
        setTimeout(() => {
            secaoVinculo.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'start',
            });
        }, 200);
    };

    const opcaoNaoAtuoProfissionalmente = (dados, setSubmitting, setFieldError) => {
        setMostrarAlerta(false);

        setCallbackEscolhaUfCidade({
            callback: (dadosUfCidade) => {
                dados.identificacao.uf = dadosUfCidade.uf;
                dados.identificacao.cidade = dadosUfCidade.municipio;
                setMostrarModalEscolhaUfCidade(false);
                preencherUsuarioSenha(dados, setSubmitting, setFieldError);
            },
        });

        setMostrarModalEscolhaUfCidade(true);
    };


    const preencherUsuarioSenha = (dados, setSubmitting, setFieldError) => {
        setCallbackEscolhaUsuarioSenha({
            callback: (novaSenha, confirmacaoSenha) => {
                dados.identificacao.novaSenhaAleatoria = false;
                dados.identificacao.novaSenha = novaSenha;
                dados.identificacao.confirmacaoSenha = confirmacaoSenha;
                submitForm(dados, setSubmitting, setFieldError);
            },
        });

        setMostrarModalEscolhaUsuarioSenha(true);
    };

    const submitForm = (dados, setSubmitting, setFieldError) => {
        setMostrarAlerta(false);
        setSubmitting(true);

        dados.identificacao.cpf = dados.identificacao.cpf.replace(/\D/g, '');
        dados.identificacao.instancia = global.gConfig.basename;

        if (dados.identificacao.cidade?.id) {
            dados.identificacao.cidade = dados.identificacao.cidade.id;
        }

        let tipo, titulo, mensagem;
        const ADM_API_BASE_URL = global.gConfig.url_base_administrativo;
        axios
            .post(`${ADM_API_BASE_URL}/solicitacao-cadastro`, dados)
            .then((response) => {
                setMostrarModalEscolhaUsuarioSenha(false);
                tipo = 'success';
                titulo = strings.sucesso;
                mensagem = response.data.message;
                setOnCloseAlerta(okSucessoSolicitacao);
                setOpcoesAlerta([
                    {
                        title: strings.ok,
                        onClick: okSucessoSolicitacao(),
                    },
                ]);
            })
            .catch((err) => {
                const { response } = err;

                tipo = 'error';
                titulo = strings.erro;
                if (response && response.status === HttpStatus.BAD_REQUEST) {
                    let arrMensagem = [];
                    if (mensagem) {
                        mensagem.forEach((error) => {
                            arrMensagem.push(`- ${error.message}\n`);
                        });
                    }
                    if (response.data?.errors) {
                        response.data.errors.forEach((error) => {
                            arrMensagem.push(`- ${error.message}\n`);
                        });
                    }
                    if (arrMensagem.length > 0) {
                        mensagem = arrMensagem;
                    }
                } else if (response && (response.status === HttpStatus.CONFLICT)) {
                    const { data } = response;
                    mensagem = data.message;
                    mensagem = response.data.errors
                }

                setOpcoesAlerta([
                    {
                        title: strings.ok,
                        onClick: () => {
                            handleCloseAlerta();
                            let bloco = null;
                            if (response.data?.conselhoDuplicado) {
                                bloco = secaoProfissao.current;
                                setFieldError(`${DADOS_PROFISSIONAIS}.${NUMERO_CONSELHO}`, strings.numeroConselhoTrabalhoDuplicado);
                            }

                            if (response.data?.emailDuplicado) {
                                bloco = secaoContato.current;
                                setFieldError(`${CONTATO}.${EMAIL}`, strings.emailDuplicado);
                            }

                            if (response.data?.erroMaioridade) {
                                bloco = secaoComplemento.current;
                                setFieldError(`${COMPLEMENTO}.${DATA_NASCIMENTO}`, strings.idadeMaiorDezoito);
                            }

                            if (bloco) {
                                setTimeout(() => {
                                    bloco.scrollIntoView({
                                        behavior: 'smooth',
                                        block: 'center',
                                        inline: 'start',
                                    });
                                }, 200);
                            }
                        },
                    },
                ]);
            })
            .finally(() => {
                setSubmitting(false);
                setTipoAlerta(tipo);
                setTituloAlerta(titulo);
                setMensagemAlerta(mensagem);
                setMostrarAlerta(true);
            });
    };

    const verificarVinculoFuncionario = (dados, { setSubmitting, setFieldError }) => {
        setSubmitting(false);
        if (!dados.vinculo?.instituicoes.length) {
            let tipo = 'alert';
            let titulo = strings.atencao;
            let mensagem = strings.mensagemFaltaVinculoSolicitacao;
            setOpcoesAlerta([
                {
                    title: strings.opcaoVoltarPreencherVinculo,
                    onClick: () => opcaoVoltarPreencherVinculo(),
                },
                {
                    title: strings.opcaoNaoAtuoProfissionalmente,
                    onClick: () => opcaoNaoAtuoProfissionalmente(dados, setSubmitting, setFieldError),
                },
            ]);

            setSubmitting(false);
            setTipoAlerta(tipo);
            setTituloAlerta(titulo);
            setMensagemAlerta(mensagem);
            setMostrarAlerta(true);
            return;
        }

        preencherUsuarioSenha(dados, setSubmitting, setFieldError);
    };

    const okSucessoSolicitacao = () => (event) => {
        window.location = global.gConfig.url_redirect_solicitacao;
    };

    useEffect(() => {
        if (dadosCadsusCnes && Object.values(dadosCadsusCnes)) {
            setTipoAlerta('alert');
            setTituloAlerta(strings.atencao);
            setMensagemAlerta(strings.avisoPesquisaCadsus);
            setMostrarAlerta(true);
        }
    }, [dadosCadsusCnes]);

    return initialValues ? (
        <>
            <Formik innerRef={ref} initialValues={initialValues} validationSchema={schema} onSubmit={verificarVinculoFuncionario} validateOnChange={false}>
                {({ values, isSubmitting, errors, handleSubmit, submitForm }) => {
                    return (
                        <SttContainer>
                            <form onSubmit={handleSubmit} noValidate>
                                <SttHeading variant="h2" color="primary" align="center" className={classes.header}>
                                    {strings.novoCadastro}
                                </SttHeading>
                                {!(
                                    dadosCadsusCnes &&
                                    dadosCadsusCnes.nome &&
                                    dadosCadsusCnes.cpf &&
                                    dadosCadsusCnes.sigla_sexo &&
                                    dadosCadsusCnes.id_estado &&
                                    dadosCadsusCnes.id_cidade
                                ) && (
                                        <>
                                            {/* Identificação */}
                                            <SttExpansionPanel
                                                title={strings.identificacao}
                                                classegriditem={classes.expansionPanel}
                                                children={
                                                    <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                        <div ref={secaoIdentificacao}></div>
                                                        <Identificacao />
                                                    </Suspense>
                                                }
                                            />
                                            <Divider />
                                        </>
                                    )}

                                {!(dadosCadsusCnes && dadosCadsusCnes.nome_mae && dadosCadsusCnes.data_nascimento) && (
                                    <>
                                        <SttExpansionPanel
                                            title={strings.complemento}
                                            classegriditem={classes.expansionPanel}
                                            children={
                                                <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                    <div ref={secaoComplemento}></div>
                                                    <Complemento />
                                                </Suspense>
                                            }
                                        />
                                        <Divider />
                                    </>
                                )}
                                <SttExpansionPanel
                                    title={strings.contato}
                                    classegriditem={classes.expansionPanel}
                                    children={
                                        <Suspense fallback={<SttCircularProgress color="primary" />}>
                                            <div ref={secaoContato}></div>
                                            <Contato />
                                        </Suspense>
                                    }
                                />
                                <Divider />
                                <SttExpansionPanel
                                    title={strings.dadosProfissionais}
                                    classegriditem={classes.expansionPanel}
                                    children={
                                        <Suspense fallback={<SttCircularProgress color="primary" />}>
                                            <div ref={secaoProfissao}></div>
                                            <Profissao />
                                        </Suspense>
                                    }
                                />
                                <Divider />
                                <SttExpansionPanel
                                    title={strings.maisMedicosResidencia}
                                    classegriditem={classes.expansionPanel}
                                    children={
                                        <Suspense fallback={<SttCircularProgress color="primary" />}>
                                            <Participacao
                                                acoesAlerta={{
                                                    setMostrarAlerta,
                                                    setTituloAlerta,
                                                    setTipoAlerta,
                                                    setMensagemAlerta,
                                                    setOpcoesAlerta,
                                                }}
                                            />
                                        </Suspense>
                                    }
                                />
                                <Divider />
                                <SttExpansionPanel
                                    title={strings.vinculos}
                                    classegriditem={classes.expansionPanel}
                                    children={
                                        <Suspense fallback={<SttCircularProgress color="primary" />}>
                                            <div ref={secaoVinculo}></div>
                                            <Vinculo
                                                acoesAlerta={{
                                                    setMostrarAlerta,
                                                    setTituloAlerta,
                                                    setTipoAlerta,
                                                    setMensagemAlerta,
                                                    setOpcoesAlerta,
                                                }}
                                            />
                                        </Suspense>
                                    }
                                />
                                <Divider />
                                {
                                    global.gConfig.termo_autorizacao_solicitacao &&
                                    <>
                                        <SttExpansionPanel
                                            title={strings.termosAutorizacoes}
                                            classegriditem={classes.expansionPanel}
                                            children={
                                                <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                    <div ref={secaoTermo}></div>
                                                    <TermoAutorizacao acoesAlerta={{
                                                        setMostrarAlerta,
                                                        setTituloAlerta,
                                                        setTipoAlerta,
                                                        setMensagemAlerta,
                                                        setOpcoesAlerta,
                                                    }} />
                                                </Suspense>
                                            }
                                        />
                                        <Divider />
                                    </>
                                }

                                <Divider />
                                <ReCAPTCHA ref={recaptchaRef} onChange={submitForm} size="invisible" sitekey={Configs.GOOGLE_RECAPTCHA_KEY} />
                                <div className={classes.buttonWrapper}>
                                    <SttButton
                                        type="submit"
                                        variant="contained"
                                        className={classes.button}
                                        color="primary"
                                        nomarginleft="true"
                                        disabled={isSubmitting || !values.autorizacao.termoUsoAceito}
                                        onClick={() => {
                                            scrollTela(errors);
                                            setDadosVerificacaoSenha({
                                                nome: values.identificacao.nome,
                                                dataNascimento: values.complemento.dataNascimento,
                                                cpf: values.identificacao.cpf,
                                                email: values.contato.email,
                                            });
                                        }}
                                    >
                                        {strings.salvar}
                                    </SttButton>
                                </div>
                            </form>
                            <SttLoading open={isSubmitting} text={strings.salvandoSolicitacao} />
                            <Alerta
                                open={mostrarAlerta}
                                title={tituloAlerta}
                                message={mensagemAlerta}
                                type={tipoAlerta}
                                options={opcoesAlerta}
                                onClose={onCloseAlerta}
                            />
                            <ModalEscolhaUfCidade
                                callbackEscolhaUfCidade={callbackEscolhaUfCidade}
                                strings={strings}
                                mostrarModalEscolhaUfCidade={mostrarModalEscolhaUfCidade}
                                setMostrarModalEscolhaUfCidade={setMostrarModalEscolhaUfCidade}
                                idCidade={values?.identificacao?.cidade}
                                idUf={values?.identificacao?.uf}
                            />
                            <SttSenha
                                modal={true}
                                openSenha={mostrarModalEscolhaUsuarioSenha}
                                setOpenSenha={setMostrarModalEscolhaUsuarioSenha}
                                strings={strings}
                                dadosUsuario={dadosVerificacaoSenha}
                                enviarForm={callbackEscolhaUsuarioSenha}
                            />
                        </SttContainer>
                    );
                }}
            </Formik>
        </>
    ) : (
        <div className={classes.carregando}>
            <SttCircularProgress color="primary" />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        cpf: state.solicitacaoCadastroReducer.aviso.cpf,
        dadosSolicitacao: state.solicitacaoCadastroReducer.aviso.dadosSolicitacao,
        dadosCadsusCnes: state.solicitacaoCadastroReducer.aviso.dadosCadsusCnes,
    };
};

export default connect(mapStateToProps, null)(translate('SolicitacaoIndex')(Solicitacao));
