import React from 'react';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { createRoot } from 'react-dom/client';
import TagManager from 'react-gtm-module';
import * as Sentry from '@sentry/browser';
import detectBrowserLanguage from 'detect-browser-language';
// MSAL imports
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfig } from "./azure-ad/authConfig";
import { ORIGEM_AUTENTICACAO_EXTERNA } from './common/Constants';

let msalInstance = null;

export const retornaMsalInstance = () => {
    return msalInstance;
}

const initializeApp = (config) => {
    const sentryUrl = 'https://a9e93e976a6c40efb4f38b9d22be7cfc@sentry.telessaude.ufsc.br/2';
    /**
     * SENTRY - https://docs.sentry.io/
     *
     * Inicialização do plugin
     */
    if (process.env.REACT_APP_SENTRY_RELEASE) {
        Sentry.init({
            dsn: sentryUrl,
            release: process.env.REACT_APP_SENTRY_RELEASE,
        });
    }

    /** Google Tag Manager */
    const tagManagerArgs = {
        gtmId: 'GTM-5G385ZS',
    };
    TagManager.initialize(tagManagerArgs);

    /*eslint-enable */
    global.gConfig = config;

    const DEFAULT_LANGUAGE = global.gConfig.default_language;
    const currentBrowserLanguage = detectBrowserLanguage();

    let currentLanguage = DEFAULT_LANGUAGE;
    if (currentBrowserLanguage.includes('en')) {
        currentLanguage = 'en';
    }
    currentLanguage = 'ptbr';
    
    const domNode = document.getElementById('root');
    const root = createRoot(domNode);
    

    // Verificar se a autenticação pela AZURE está habilitada e realizar inicialização
    const initializeMsalInstance = async () => {
        if (global.gConfig.autenticacao_externa.ativo &&
            global.gConfig.autenticacao_externa.origem.filter(origem => origem.identificador === ORIGEM_AUTENTICACAO_EXTERNA.AZURE_AD)?.length > 0 &&
            global.gConfig.autenticacao_externa.origem.filter(origem => origem.identificador === ORIGEM_AUTENTICACAO_EXTERNA.AZURE_AD)[0].ativo) {
            msalInstance = new PublicClientApplication(msalConfig(global.gConfig));

            await msalInstance.initialize();
            // Default to using the first account if no account is active on page load
            if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
                // Account selection logic is app dependent. Adjust as needed for different use cases.
                msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
            }

            // Optional - This will update account state if a user signs in from another tab or window
            msalInstance.enableAccountStorageEvents();

            msalInstance.addEventCallback((event) => {
                if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
                    const account = event.payload.account;
                    msalInstance.setActiveAccount(account);
                }
            });

            return msalInstance;
        }
        return null;
    };

    initializeMsalInstance().then(pca => {
        root.render(<App modulo={'administrativo'} currentLanguage={currentLanguage} pca={pca} />);
    });
};

const verificarBaseAlternativa = (config) => {
    let subpaths = window.location.pathname;
    if (subpaths && config.layoutsAlternativos) {
        subpaths = subpaths.split('/');
        let layoutAlternativo = null;
        subpaths.forEach((sp) => {
            if (config.layoutsAlternativos.includes(sp)) {
                layoutAlternativo = sp;
                return true;
            }
        });

        if (layoutAlternativo) {
            return fetch(`${process.env.PUBLIC_URL}/config/layoutsAlternativos/${layoutAlternativo}/config.stt-${config.config_id}.json`, {
                headers: { 'Content-Type': 'application/json' },
            }).then((response) => response.json());
        }
    }
    return config;
};

Promise.all([
    fetch(`${process.env.PUBLIC_URL}/config/changelog.json`).then((response) => response.json()),
    fetch(`${process.env.PUBLIC_URL}/config/config.json`).then((response) => response.json()),
])
    .then(([changelog, config]) => Promise.all([changelog, verificarBaseAlternativa(config)]))
    .then(([changelog, config]) => initializeApp({ ...config, ...changelog }))
    .catch((error) => {
        console.error('Erro ao buscar os arquivos de configurações do frontend', error);
    });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
