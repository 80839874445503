import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import validarCpf from 'validar-cpf';
import httpStatus from 'http-status-codes';
import {
    SttFormGroup,
    SttButton,
    SttMaskedInput,
    SttFormHelperText,
    SttLoading,
    SttAlerta
} from '@stt-componentes/core';
import ConstantesSolicitacaoCadastro from '@constantes/solicitacao-cadastro';
import { KEY_RETURN } from 'keycode-js';
import translate from "@componentes/translate";
import axios from 'axios';

const { REDUCER_TYPE, SITUACAO, CODIGO_MENSAGEM_AVISO } = ConstantesSolicitacaoCadastro;

const INITIAL_STATE_ALERTA = {
    titulo: '',
    mensagem: '',
    tipo: 'alert',
    visivel: false,
    botoes: [],
    onClose: () => null
};

const BuscaCpf = ({ dispatch, classes, strings, aviso }) => {
    const [state, setState] = useState({
        cpf: '',
        carregando: false
    });

    const [alertaState, setAlertaState] = useState(INITIAL_STATE_ALERTA);

    const handleChangeCpf = event => {
        setState({ ...state, cpf: event.target.value });
    };

    const okMultiplosUsuarios = (usuarios) => event => {
        dispatch({
            type: REDUCER_TYPE.SELECIONAR_USUARIO_SOLICITACAO_AVISO,
            situacaoSolicitacao: SITUACAO.SELECAO_USUARIO_NOVA_SENHA,
            usuarios: usuarios
        });
    };

    const okSolicitacaoCadastro = (cpf, dadosSolicitacao, dadosCadsusCnes) => event => {
        dispatch({
            type: REDUCER_TYPE.ABRIR_SOLICITACAO_CADASTRO,
            situacaoSolicitacao: SITUACAO.SOLICITACAO_CADASTRO,
            cpf: cpf,
            dadosSolicitacao: { ...dadosSolicitacao, ...aviso.dadosSolicitacao },
            dadosCadsusCnes: dadosCadsusCnes
        });
    };

    const handleOnClose = () => {
        setAlertaState({ ...alertaState, visivel: false });
    };

    const onClose = () => handleOnClose;

    useEffect(() => {
        if (aviso.dadosSolicitacao?.cpf) {
            setState({ ...state, cpf: aviso.dadosSolicitacao.cpf, carregando: true, oidAzure: aviso.dadosSolicitacao.oid });
        }
    }, []);

    useEffect(() => {
        if (state.carregando) {
            const cpf = state.cpf;
            const cpfSemMascara = (cpf || '').replace(/\D+/g, '');

            axios.get(`${global.gConfig.url_base_administrativo}/solicitacao-cadastro`, { params: { cpf: cpfSemMascara } })
                .then(function ({ data }) {
                    if (data.code === CODIGO_MENSAGEM_AVISO.MULTIPLOS_USUARIOS) {
                        if (global.gConfig.basename === 'ebserh' || global.gConfig.config_id === 'ebserh') {
                            setAlertaState({
                                ...alertaState,
                                visivel: true,
                                mensagem: strings.mensagemUsuarioJaExisteEbserh,
                                tipo: 'alert',
                                titulo: strings.atencao,
                                onClose: () => () => {
                                    window.location.href = global.gConfig.url_redirect_solicitacao;
                                },
                                botoes: [
                                    {
                                        title: strings.irParaLogin,
                                        onClick: () => {
                                            window.location.href = global.gConfig.url_redirect_solicitacao;
                                        }
                                    }
                                ]
                            });
                        } else {
                            setAlertaState({
                                ...alertaState,
                                visivel: true,
                                mensagem: data.message,
                                tipo: 'alert',
                                titulo: strings.atencao,
                                onClose: () => okMultiplosUsuarios(data.data),
                                botoes: [
                                    {
                                        title: strings.ok,
                                        onClick: okMultiplosUsuarios(data.data)
                                    }
                                ]
                            });
                        }
                        return;
                    }
                    if (data.code === CODIGO_MENSAGEM_AVISO.SEM_SOLICITACAO ||
                        data.code === CODIGO_MENSAGEM_AVISO.SOLICITACAO_PENDENTE ||
                        data.code === CODIGO_MENSAGEM_AVISO.FUNCIONARIO_SEM_USUARIO) {
                        const dadosSolicitacao = data.data;
                        const dadosCadsusCnes = data.dadosCadsusCnes;
                        setAlertaState({
                            ...alertaState,
                            visivel: true,
                            mensagem: data.message,
                            tipo: 'alert',
                            titulo: strings.atencao,
                            onClose: () => okSolicitacaoCadastro(cpf, dadosSolicitacao, dadosCadsusCnes),
                            botoes: [
                                {
                                    title: strings.ok,
                                    onClick: okSolicitacaoCadastro(cpf, dadosSolicitacao, dadosCadsusCnes)
                                }
                            ]
                        });
                    }
                })
                .catch(function (error) {
                    const { response } = error;
                    console.log(error)
                    if (!response) {
                        setAlertaState({
                            ...alertaState,
                            visivel: true,
                            mensagem: strings.mensagemErroGeral,
                            tipo: 'error',
                            titulo: strings.erro,
                            onClose: () => onClose(),
                            botoes: [
                                {
                                    title: strings.ok,
                                    onClick: onClose()
                                }
                            ]
                        });
                        return;
                    }
                    const { data, status } = response;
                    if (status === httpStatus.BAD_REQUEST) {
                        setAlertaState({
                            ...alertaState,
                            visivel: true,
                            mensagem: data.errors[0].message,
                            tipo: 'error',
                            titulo: strings.erro,
                            onClose: () => onClose(),
                            botoes: [
                                {
                                    title: strings.ok,
                                    onClick: onClose()
                                }
                            ]
                        });
                    } else if (status === httpStatus.INTERNAL_SERVER_ERROR) {
                        setAlertaState({
                            ...alertaState,
                            visivel: true,
                            mensagem: strings.mensagemErroGeral,
                            tipo: 'error',
                            titulo: strings.erro,
                            onClose: () => onClose(),
                            botoes: [
                                {
                                    title: strings.ok,
                                    onClick: onClose()
                                }
                            ]
                        });
                    }
                })
                .finally(function () {
                    setState({ ...state, carregando: false });
                });
        }
    }, [state.carregando]);

    const clickPesquisar = () => {
        const { carregando } = state;
        if (carregando) {
            return;
        }
        setState({ ...state, carregando: true });
    };

    const handleChangeKeyUp = (event) => {
        if (state.cpf.length < 14 || verificarCpf()) {
            return;
        }
        if (event.keyCode === KEY_RETURN) {
            clickPesquisar();
        }
    }

    const verificarCpf = () => {
        let { cpf } = state;
        cpf = (cpf || '').replace(/\D+/g, '');
        if (cpf.length < 11)
            return false;

        return !validarCpf(cpf);
    };

    return (
        <>
            <SttFormGroup row className={classes.form}>
                <SttMaskedInput label={strings.cpf}
                    name="input-cpf"
                    mask="cpf"
                    required
                    value={state.cpf}
                    onKeyUp={handleChangeKeyUp}
                    error={verificarCpf()}
                    onChange={handleChangeCpf}
                />
                {
                    verificarCpf() ? (
                        <SttFormHelperText error={true}
                            name="helper-cpf-invalido"
                            className={classes.errorText}>
                            {strings.cpfInvalido}
                        </SttFormHelperText>
                    ) : null
                }
                <SttButton variant="contained"
                    name="btn-confirmar-cpf"
                    color="primary"
                    nomarginleft="true"
                    disabled={state.cpf.length < 14 || verificarCpf()}
                    className={classes.buttons}
                    onClick={clickPesquisar}>
                    {strings.pesquisar}
                </SttButton>
            </SttFormGroup>
            <SttLoading
                open={state.carregando}
                text={strings.buscandoDadosCpf}
            />
            <SttAlerta open={alertaState.visivel}
                title={alertaState.titulo}
                message={alertaState.mensagem}
                type={alertaState.tipo}
                options={alertaState.botoes}
                onClose={alertaState.onClose()}
            />
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        aviso: state.solicitacaoCadastroReducer.aviso
    };
};

export default connect(mapStateToProps, null)(translate('SolicitacaoBuscaCpf')(BuscaCpf));