import React, { useState } from "react";
import { connect } from 'react-redux';
import {
    SttFormGroup,
    SttFormControlLabel,
    SttCheckbox,
    SttButton,
    SttAlerta
} from '@stt-componentes/core';
import ConstantesSolicitacaoCadastro from '@constantes/solicitacao-cadastro';
import translate from "@componentes/translate";
import { loginRequest } from "../../../../azure-ad/authConfig";
import { useMsal } from "@azure/msal-react";
import util from '@src/util/index';
import { makeStyles } from "@material-ui/core/styles";
const { ServerError, ClientAuthError, BrowserAuthError } = require('@azure/msal-browser');
const { REDUCER_TYPE, SITUACAO } = ConstantesSolicitacaoCadastro;

const useStyles = makeStyles(theme => ({
    botaoExterno: {
        boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
        color: '#fbfbfb',
        backgroundColor: theme.palette.externo.main,
        '&:hover': {
            boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important',
            backgroundColor: theme.palette.externo.secondary,
        }
    }
}));

const ConfirmacaoMensagem = ({ aviso, dispatch, classes, strings }) => {

    const classesCss = useStyles();

    const handleCloseAlerta = () => {
        setMostrarAlerta(false);
    }

    const [mostrarAlerta, setMostrarAlerta] = useState(false);
    const [tituloAlerta, setTituloAlerta] = useState('');
    const [mensagemAlerta, setMensagemAlerta] = useState('');
    const [tipoAlerta, setTipoAlerta] = useState('alert');
    const [opcoesAlerta, setOpcoesAlerta] = useState([]);
    const [onCloseAlerta, setOnCloseAlerta] = useState(() => handleCloseAlerta);
    const { instance } = useMsal();

    const recuperarCredenciaisAzureAd = () => {
        instance.loginPopup(loginRequest)
            .then(response => {
                const userId = response.idTokenClaims.oid;
                const userEmail = response.idTokenClaims.preferred_username || response.idTokenClaims.email;

                //Verificar se o CPF da credencial é igual ao do usuário
                util.recuperarCpfAzure(userId, response.accessToken)
                    .then((cpf) => {
                        dispatch(clickConfirm({ oid: userId, email: userEmail, cpf: cpf }));
                    }).catch((error) => {
                        console.log(error);
                    });
            })
            .catch(error => {
                console.error('Erro na autenticação externa AZURE', error);
                if (error instanceof ServerError || error instanceof ClientAuthError) {
                    setTipoAlerta('error');
                    setMensagemAlerta(strings.erroGeralAutenticacaoExterna);
                    setOnCloseAlerta(() => () => {
                        setMostrarAlerta(false);
                    })
                    setOpcoesAlerta([
                        {
                            title: strings.ok,
                            onClick: () => {
                                setMostrarAlerta(false);
                            }
                        }
                    ]);
                    setTituloAlerta(strings.erro);
                    setMostrarAlerta(true);
                } else if (error instanceof BrowserAuthError && error.errorCode === "interaction_in_progress") {
                    setMensagemAlerta(strings.erroAutenticacaoExternaJaAberta);
                    setOnCloseAlerta(() => () => {
                        setMostrarAlerta(false);
                    })
                    setOpcoesAlerta([
                        {
                            title: strings.ok,
                            onClick: () => {
                                setMostrarAlerta(false);
                            }
                        }
                    ]);
                    setTituloAlerta(strings.atencao);
                    setMostrarAlerta(true);
                }
            });
    }

    const handleCheckChange = (checkEntendi) => {
        return {
            type: REDUCER_TYPE.MARCAR_ENTENDI_AVISO,
            checkEntendi: !checkEntendi
        };
    };

    const clickConfirm = (dadosSolicitacao) => {
        return {
            type: REDUCER_TYPE.CONFIRMAR_ENTENDI_AVISO,
            situacaoSolicitacao: SITUACAO.BUSCA_CPF,
            dadosSolicitacao: dadosSolicitacao
        };
    };

    return (
        <SttFormGroup row className={classes.form}>
            <SttFormControlLabel
                control={
                    <SttCheckbox
                        name="check-aviso"
                        onChange={() => dispatch(handleCheckChange(aviso.checkEntendi))}
                        value={aviso.checkEntendi}
                        color="primary"
                    />
                }
                label={strings.okEntendi}
            />

            {
                global.gConfig.config_id === 'ebserh' || global.gConfig.basename === 'ebserh' ?
                    <SttButton variant="contained"
                        name="btn-confirmar-aviso"
                        color="primary"
                        nomarginleft="true"
                        className={[classes.buttons, classesCss.botaoExterno]}
                        disabled={!aviso.checkEntendi}
                        onClick={() => {
                            recuperarCredenciaisAzureAd();
                        }}>
                        {strings.confirmar}
                    </SttButton> :
                    <SttButton variant="contained"
                        name="btn-confirmar-aviso"
                        color="primary"
                        nomarginleft="true"
                        className={classes.buttons}
                        disabled={!aviso.checkEntendi}
                        onClick={() => dispatch(clickConfirm())}>
                        {strings.confirmar}
                    </SttButton>
            }


            <SttAlerta
                open={mostrarAlerta}
                title={tituloAlerta}
                message={mensagemAlerta}
                type={tipoAlerta}
                options={opcoesAlerta}
                onClose={onCloseAlerta}
            />
        </SttFormGroup>
    );
}

const mapStateToProps = (state) => {
    return {
        aviso: state.solicitacaoCadastroReducer.aviso
    };
};

export default connect(mapStateToProps, null)(translate('SolicitacaoConfirmacaoMensagem')(ConfirmacaoMensagem));