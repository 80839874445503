import TYPES from '@redux/types/cadastro/funcionario';

export const setParametrosPesquisaInstituicao = (parametros) => {
    return {
        type: TYPES.SETAR_PARAMETROS_PESQUISA_INSTITUICAO,
        payload: { parametros }
    };
}

export const limparParametrosPesquisaInstituicao = () => {
    return {
        type: TYPES.LIMPAR_PARAMETROS_PESQUISA_INSTITUICAO
    };
}

export const setPesquisarInstituicao = (pesquisar) => {
    return {
        type: TYPES.PESQUISAR_INSTITUICAO,
        payload: { pesquisarInstituicao: pesquisar }
    };
}

export const setIdFuncionarioAlteracao = (id) => {
    return {
        type: TYPES.SETAR_ID_FUNCIONARIO_ALTERACAO,
        payload: { idFuncionario: id }
    };
}

export const setDadosFuncionarioAlteracao = (dados) => {
    return {
        type: TYPES.SETAR_DADOS_FUNCIONARIO_ALTERACAO,
        payload: {
            dadosFuncionario: {
                nome: dados.nome,
                data_nascimento_formatada: dados.data_nascimento_formatada,
                cpf: dados.cpf,
                usuarios: dados.usuarios
            }
        }
    };
}

export const setInstituicoesAdminLocal = (instituicoes) => {
    return {
        type: TYPES.SETAR_INSTITUICOES_ADMIN_LOCAL,
        payload: { instituicoes: instituicoes }
    };
}