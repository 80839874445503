import React, { useRef, useState, useEffect, memo, Suspense, lazy } from "react";
import {
    SttExpansionPanel,
    SttDivider,
    SttContainer,
    SttLoading,
    SttHeading,
    SttCircularProgress,
    SttTranslate,
    SttButton
} from '@stt-componentes/core';
import translate from '@componentes/translate';
import axios from 'axios';
import { connect } from 'react-redux';
import HttpStatus from 'http-status-codes';
import { Formik } from 'formik';
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import UtilPontos from '@util/pontos';
import validationSchema from './validationSchema';
import { getHeaders } from '@src/request';
import {
    setPontoAlteracao as setPontoAlteracaoAction
} from '@redux/actions/cadastro/ponto/index';
import {
    setParametrosAlerta as setParametrosAlertaAction,
    setOpen as setOpenAction
} from '@redux/actions/alerta';
import { PONTOS } from "@src/common/Constants";
import Operacoes from "./operacoes";
import { useLocation } from "react-router-dom";

const DadosGerais = lazy(() => import('@componentes/interno/cadastro/pontos/dadosGerais'));
const DadosTipo = lazy(() => import('@componentes/interno/cadastro/pontos/dadosTipo'));
const DadosVinculo = lazy(() => import('@componentes/interno/cadastro/pontos/dadosVinculo'));
const DadosAtividade = lazy(() => import('@componentes/interno/cadastro/pontos/dadosAtividade'));
const DadosContato = lazy(() => import('@componentes/interno/cadastro/pontos/dadosContato'));
const DadosExameUsuario = lazy(() => import('@componentes/interno/cadastro/pontos/dadosExameUsuario'));

const useStyles = makeStyles(theme => ({
    buttonWrapper: {
        marginTop: theme.spacing(1)
    },
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
    header: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    botaoVoltar: {
        marginTop: theme.spacing(2)
    }
}));

const Divider = memo((props) => {
    return (
        <SttDivider {...props} />
    )
});

const CadastroPontoIndex = (props) => {
    const {
        strings,
        user,
        idPontoAlteracao,
        setParametrosAlerta,
        setOpen,
        setPontoAlteracao
    } = props;

    const schema = validationSchema(strings);
    const formRef = useRef();
    const classes = useStyles();
    const navigate = useNavigate();
    const location = useLocation();
    const [initialValues, setInitialValues] = useState(null);
    const [carregado, setCarregado] = useState(false);
    const [modalOperacao, setModalOperacao] = useState({
        open: false,
        operacao: null,
    })

    const ADM_API_BASE_URL = global.gConfig.url_base_administrativo;
    const secaoDadosGerais = useRef(null);
    const secaoDadosTipo = useRef(null);
    const secaoDadosVinculo = useRef(null);
    const [carregando, setCarregando] = useState(false);
    const [secoesAbertas, setSecoesAbertas] = useState({
        'dadosGerais': true,
        'dadosTipo': false,
        'dadosVinculo': false,
        'dadosAtividade': false,
        'dadosContato': false,
        'dadosExameUsuarios': false,
    });

    useEffect(() => {
        if (idPontoAlteracao) {
            //Existe um funcionário para alteração
            axios.get(`${ADM_API_BASE_URL}/ponto/${idPontoAlteracao}`, { headers: getHeaders() })
                .then((response) => {
                    setInitialValues(UtilPontos.inicializarValoresFormulario(response.data.data));
                    setCarregado(true);
                })
                .catch(err => {
                    console.log(err);
                });
        } else {
            //Irá cadastrar um ponto do começo
            setInitialValues(UtilPontos.inicializarValoresFormulario());
            setCarregado(true);
        }
    }, [idPontoAlteracao]);

    const enviarFormulario = (dados, setSubmitting) => {
        setSubmitting(true);
        axios.post(`${ADM_API_BASE_URL}/ponto`, dados, { headers: getHeaders() })
            .then((response) => {
                setSubmitting(false);
                const { data } = response;

                const parametrosAlerta = {
                    titulo: strings.sucesso,
                    mensagem: data.message,
                    tipo: 'success',
                    open: true,
                    opcoes: [
                        {
                            title: strings.ok,
                            onClick: redirectPesquisa
                        }
                    ],
                    onClose: redirectPesquisa
                };

                setParametrosAlerta(parametrosAlerta);
            })
            .catch(err => {
                setSubmitting(false);

                const { response } = err;
                let mensagem = strings.mensagemErroGeral;
                if (response) {
                    if (response.status === HttpStatus.BAD_REQUEST) {
                        const { data } = response;
                        let arrMensagem = [];
                        data.errors.forEach(error => {
                            arrMensagem.push(`- ${error.message}`);
                        });
                        if (arrMensagem.length > 0) {
                            mensagem = arrMensagem.join('\n');
                        }
                    }
                }

                const parametrosAlerta = {
                    titulo: strings.erro,
                    mensagem: mensagem,
                    tipo: 'error',
                    open: true,
                    opcoes: [
                        {
                            title: strings.ok,
                            onClick: () => {
                                setOpen(false);
                            }
                        }
                    ]
                };

                setParametrosAlerta(parametrosAlerta);
            });
    };

    const redirectPesquisa = () => {
        setOpen(false);
        setPontoAlteracao(null);
        navigate("/pesquisa/ponto", { state: { pesquisar: true } });
    }

    const abrirSecao = (secao, estado) => {
        let novoSecoesAbertas = {
            ...secoesAbertas
        };
        for (const secaoAberta in novoSecoesAbertas) {
            novoSecoesAbertas[secaoAberta] = false;
        }

        novoSecoesAbertas[secao] = estado;
        setSecoesAbertas(novoSecoesAbertas);
    }

    const verificarSecoesComErro = (validateForm) => {
        setCarregando(true);
        validateForm().then((retorno) => {
            let node = null;
            if (retorno['dadosGerais']) {
                abrirSecao('dadosGerais', true);
                node = secaoDadosGerais.current;
            } else if (retorno['dadosTipo']) {
                abrirSecao('dadosTipo', true);
                node = secaoDadosTipo.current;
            } else if (retorno['dadosVinculo']) {
                abrirSecao('dadosVinculo', true);
                node = secaoDadosVinculo.current;
            }

            if (node) {
                setTimeout(() => {
                    node.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                        inline: 'start'
                    });
                }, 200);
            }
            setCarregando(false);
        });
    }

    return (
        <>
            {
                carregado ?
                    <Formik
                        innerRef={formRef}
                        initialValues={initialValues}
                        enableReinitialize
                        validationSchema={schema}
                        validateOnChange={false}
                        onSubmit={(dados, { setSubmitting }) => {
                            let mensagem = strings.confirmacaoCadastro;
                            setSubmitting(false);
                            if (dados.id) {
                                mensagem = strings.confirmacaoAlteracao;
                            }

                            const parametrosAlerta = {
                                titulo: strings.atencao,
                                mensagem: mensagem,
                                tipo: 'alert',
                                open: true,
                                opcoes: [
                                    {
                                        title: strings.ok,
                                        onClick: () => {
                                            enviarFormulario(dados, setSubmitting);
                                            setOpen(false);
                                        }
                                    },
                                    {
                                        title: strings.cancelar,
                                        onClick: () => {
                                            setOpen(false);
                                        }
                                    }
                                ]
                            };
                            setParametrosAlerta(parametrosAlerta);
                        }}
                    >
                        {
                            ({
                                values,
                                isSubmitting,
                                handleSubmit,
                                validateForm,
                                resetForm
                            }) => {
                                return (
                                    <SttContainer>
                                        <form onSubmit={handleSubmit} noValidate>
                                            <SttButton
                                                type="button"
                                                variant="outlined"
                                                color="primary"
                                                className={classes.botaoVoltar}
                                                onClick={() => {
                                                    const parametrosAlerta = {
                                                        titulo: strings.confirmacao,
                                                        mensagem: strings.retornarTelaPesquisa,
                                                        tipo: 'alert',
                                                        open: true,
                                                        opcoes: [
                                                            {
                                                                title: strings.sim,
                                                                onClick: () => {
                                                                    setPontoAlteracao(null);
                                                                    setOpen(false);
                                                                    navigate("/pesquisa/ponto", { state: { pesquisar: true } });
                                                                }
                                                            },
                                                            {
                                                                title: strings.nao,
                                                                onClick: () => {
                                                                    setOpen(false);
                                                                }

                                                            }
                                                        ]
                                                    };
                                                    setParametrosAlerta(parametrosAlerta);
                                                }}
                                                nomarginleft="true"
                                            >
                                                {strings.voltar}
                                            </SttButton>
                                            {
                                                values.trocaPonto
                                                    ?
                                                    <SttHeading variant="h1" color="primary" align="center" className={classes.header}>{strings.trocaPonto}</SttHeading>
                                                    :
                                                    <SttHeading variant="h1" color="primary" align="center" className={classes.header}>{values.id ? strings.alteracao : strings.cadastro}</SttHeading>
                                            }
                                            {/* Dados gerais */}
                                            <SttExpansionPanel
                                                title={strings.dadosGerais}
                                                opened={secoesAbertas['dadosGerais']}
                                                callback={estadoInterno => abrirSecao('dadosGerais', estadoInterno)}
                                                children={
                                                    <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                        <div ref={secaoDadosGerais}></div>
                                                        <DadosGerais />
                                                    </Suspense>
                                                }
                                            />
                                            <Divider />
                                            <SttExpansionPanel
                                                title={strings.dadosTipo}
                                                opened={secoesAbertas['dadosTipo']}
                                                callback={estadoInterno => abrirSecao('dadosTipo', estadoInterno)}
                                                children={
                                                    <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                        <div ref={secaoDadosTipo}></div>
                                                        <DadosTipo />
                                                    </Suspense>
                                                }
                                            />
                                            <Divider />
                                            <SttExpansionPanel
                                                title={strings.dadosVinculo}
                                                opened={secoesAbertas['dadosVinculo']}
                                                callback={estadoInterno => abrirSecao('dadosVinculo', estadoInterno)}
                                                children={
                                                    <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                        <div ref={secaoDadosVinculo}></div>
                                                        <DadosVinculo />
                                                    </Suspense>
                                                }
                                            />
                                            <Divider />
                                            <SttExpansionPanel
                                                title={strings.dadosAtividade}
                                                opened={secoesAbertas['dadosAtividade']}
                                                callback={estadoInterno => abrirSecao('dadosAtividade', estadoInterno)}
                                                children={
                                                    <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                        <DadosAtividade />
                                                    </Suspense>
                                                }
                                            />
                                            <Divider />
                                            <SttExpansionPanel
                                                title={strings.dadosContato}
                                                opened={secoesAbertas['dadosContato']}
                                                callback={estadoInterno => abrirSecao('dadosContato', estadoInterno)}
                                                children={
                                                    <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                        <DadosContato />
                                                    </Suspense>
                                                }
                                            />
                                            <Divider />
                                            {
                                                values.id ?
                                                    <>
                                                        <SttExpansionPanel
                                                            title={strings.dadosExameUsuarios}
                                                            opened={secoesAbertas['dadosExameUsuarios']}
                                                            callback={estadoInterno => abrirSecao('dadosExameUsuarios', estadoInterno)}
                                                            children={
                                                                <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                                    <DadosExameUsuario />
                                                                </Suspense>
                                                            }
                                                        />
                                                        <Divider />
                                                    </> : null
                                            }
                                            <div className={classes.buttonWrapper}>
                                                <SttButton
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    nomarginleft="true"
                                                    disabled={isSubmitting}
                                                    onClick={() => verificarSecoesComErro(validateForm)}
                                                >
                                                    {strings.salvar}
                                                </SttButton>
                                                {
                                                    values.id ?
                                                        <>
                                                            <SttButton
                                                                type="button"
                                                                variant="contained"
                                                                color="secondary"
                                                                disabled={isSubmitting}
                                                                onClick={() => {
                                                                    resetForm({
                                                                        values: UtilPontos.inicializarValoresTrocaPontoFormulario(initialValues)
                                                                    });
                                                                }}
                                                            >
                                                                {strings.trocaPonto}
                                                            </SttButton>
                                                            {
                                                                (values.dadosGerais.situacao.identificador === PONTOS.SITUACAO_INATIVO ||
                                                                    values.dadosGerais.situacao.identificador === PONTOS.SITUACAO_ATIVO ||
                                                                    values.dadosGerais.situacao.identificador === PONTOS.SITUACAO_BLOQUEADO) &&
                                                                <SttButton
                                                                    type="button"
                                                                    variant="contained"
                                                                    color="secondary"
                                                                    disabled={isSubmitting}
                                                                    onClick={() => {
                                                                        setModalOperacao({
                                                                            open: true,
                                                                            operacao: PONTOS.OPERACOES.DESATIVAR
                                                                        });
                                                                    }}
                                                                >
                                                                    {strings.desativar}
                                                                </SttButton>
                                                            }
                                                            {
                                                                (values.dadosGerais.situacao.identificador === PONTOS.SITUACAO_ATIVO) &&
                                                                <SttButton
                                                                    type="button"
                                                                    variant="contained"
                                                                    color="secondary"
                                                                    disabled={isSubmitting}
                                                                    onClick={() => {
                                                                        setModalOperacao({
                                                                            open: true,
                                                                            operacao: PONTOS.OPERACOES.BLOQUEAR
                                                                        });
                                                                    }}
                                                                >
                                                                    {strings.bloquear}
                                                                </SttButton>
                                                            }
                                                            {
                                                                (values.dadosGerais.situacao.identificador === PONTOS.SITUACAO_BLOQUEADO) &&
                                                                <SttButton
                                                                    type="button"
                                                                    variant="contained"
                                                                    color="secondary"
                                                                    disabled={isSubmitting}
                                                                    onClick={() => {
                                                                        setModalOperacao({
                                                                            open: true,
                                                                            operacao: PONTOS.OPERACOES.DESBLOQUEAR
                                                                        });
                                                                    }}
                                                                >
                                                                    {strings.desbloquear}
                                                                </SttButton>
                                                            }
                                                            {
                                                                ((values.dadosGerais.situacao.identificador === PONTOS.SITUACAO_DESATIVADO) ||
                                                                (values.dadosGerais.situacao.identificador === PONTOS.SITUACAO_INATIVO)) &&
                                                                <SttButton
                                                                    type="button"
                                                                    variant="contained"
                                                                    color="secondary"
                                                                    disabled={isSubmitting}
                                                                    onClick={() => {
                                                                        setModalOperacao({
                                                                            open: true,
                                                                            operacao: PONTOS.OPERACOES.ATIVAR
                                                                        });
                                                                    }}
                                                                >
                                                                    {strings.ativar}
                                                                </SttButton>
                                                            }

                                                        </> :
                                                        null
                                                }
                                            </div>
                                        </form>
                                        <SttLoading
                                            open={isSubmitting}
                                            text={strings.salvando}
                                        />
                                    </SttContainer>
                                )
                            }
                        }
                    </Formik> :
                    <div className={classes.carregando}>
                        <SttCircularProgress color="primary" />
                    </div>
            }
            <SttLoading
                open={carregando}
                text={strings.carregando}
            />
            {
                modalOperacao.open &&
                <Operacoes idPonto={idPontoAlteracao}
                    operacao={modalOperacao.operacao}
                    setExibirModalOperacao={() => setModalOperacao({ open: false, operacao: null })}
                    voltarPesquisa={redirectPesquisa}
                />
            }
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.index.user,
        idPontoAlteracao: state.cadastroPontoReducer.pontoAlteracao
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setPontoAlteracao: id => dispatch(setPontoAlteracaoAction(id)),
        setParametrosAlerta: (parametros) => dispatch(setParametrosAlertaAction(parametros)),
        setOpen: (open) => dispatch(setOpenAction(open)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SttTranslate('CadastroPonto')(CadastroPontoIndex));