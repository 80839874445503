/* eslint-disable no-unused-expressions */
import React, { useRef } from "react";
import {
    SttGrid,
    SttModal,
    SttButton
} from '@stt-componentes/core';
import { Field, Formik } from 'formik';
import translate from '@componentes/translate';
import {
    UF,
    MUNICIPIO,
} from './fieldNames';
import Uf from './uf';
import Municipio from './municipio';
import validationSchema from './validationSchema';

const ModalEscolhaUfCidade = ({ strings, mostrarModalEscolhaUfCidade, setMostrarModalEscolhaUfCidade, callbackEscolhaUfCidade, idCidade, idUf }) => {
    const schema = validationSchema(strings);
    const ref = useRef();

    return (
        <SttModal
            title={strings.escolhaUfCidade}
            open={mostrarModalEscolhaUfCidade}
            maxWidth="sm"
            outModalClose={() => setMostrarModalEscolhaUfCidade(false)}
            iconClose={() => setMostrarModalEscolhaUfCidade(false)}
            fullWidth={true}
            children={
                <Formik
                    innerRef={ref}
                    enableReinitialize
                    initialValues={
                        {
                            [UF]: idUf,
                            [MUNICIPIO]: idCidade
                        }
                    }
                    validationSchema={schema}
                    onSubmit={(dados, { setSubmitting }) => {
                        setSubmitting(false);
                        callbackEscolhaUfCidade.callback((
                            {
                                uf: dados.uf.id,
                                municipio: dados.municipio.id
                            }
                        ));
                    }}
                >
                    {
                        ({
                            handleSubmit
                        }) => {
                            return (
                                <form onSubmit={handleSubmit} noValidate>
                                    <SttGrid container spacing={1}>
                                        <SttGrid item xs={12} sm={3}>
                                            <Field name={UF}>
                                                {(props) => (
                                                    <Uf
                                                        {...props}
                                                        obrigatorio={true}
                                                    />
                                                )}
                                            </Field>
                                        </SttGrid>

                                        <SttGrid item xs={12} sm={9}>
                                            <Field name={MUNICIPIO}>
                                                {(props) => (
                                                    <Municipio
                                                        {...props}
                                                        obrigatorio={true}
                                                    />
                                                )}
                                            </Field>
                                        </SttGrid>
                                    </SttGrid>
                                </form>
                            )
                        }
                    }
                </Formik>
            }
            footer={
                <div>
                    <SttButton variant="contained"
                        color="primary"
                        onClick={() => {
                            if (ref.current) {
                                ref.current.handleSubmit();
                            }
                        }}>
                        {strings.confirmar}
                    </SttButton>
                    <SttButton variant="contained"
                        color="secondary"
                        onClick={() => setMostrarModalEscolhaUfCidade(false)}>
                        {strings.fechar}
                    </SttButton>
                </div>
            }
        />
    );
}

export default translate('SolicitacaoIndex')(ModalEscolhaUfCidade);