import TIPOS_PARTICIPACOES from '@constantes/participacao';

const formatarParaSolicitacao = (cpf, solicitacao) => {
    if (solicitacao[0]) {
        solicitacao = solicitacao[0];
    }
    const solicitacaoFormatada = {
        id: solicitacao.id,
        idAzureAd: solicitacao.oid,
        identificacao: {},
        contato: {},
        complemento: {},
        dadosProfissionais: {},
        participacao: {},
        vinculo: {},
        autorizacao: {}
    };
    // identificacao ===========================
    solicitacaoFormatada.identificacao.cpf = cpf;
    solicitacaoFormatada.identificacao.nome = solicitacao.nome || '';
    solicitacaoFormatada.identificacao.sexo = null;
    if (solicitacao.id_sexo) {
        solicitacaoFormatada.identificacao.sexo = solicitacao.id_sexo;
    } else if (solicitacao.sigla_sexo) {
        solicitacaoFormatada.identificacao.sexo = solicitacao.sigla_sexo;
    }
    solicitacaoFormatada.identificacao.uf = solicitacao.id_estado || null;
    solicitacaoFormatada.identificacao.cidade = solicitacao.id_cidade || null;

    // complemento ===========================
    solicitacaoFormatada.complemento.nomeMae = solicitacao.nome_mae || '';
    solicitacaoFormatada.complemento.dataNascimento = solicitacao.data_nascimento || null;

    // contato =====================
    solicitacaoFormatada.contato.email = solicitacao.email_pessoal || solicitacao.email || '';
    solicitacaoFormatada.contato.telefoneCelular = solicitacao.telefone_celular || '';
    solicitacaoFormatada.contato.telefoneComercial = solicitacao.telefone_comercial || '';
    solicitacaoFormatada.contato.telefoneResidencial = '';
    solicitacaoFormatada.contato.celularWhatsapp = false;

    // dadosProfissionais ===========================
    solicitacaoFormatada.dadosProfissionais.profissao = solicitacao.profissao || '';
    solicitacaoFormatada.dadosProfissionais.conselhoTrabalho = solicitacao.id_uf_conselho_trabalho ? 'S' : 'N';
    solicitacaoFormatada.dadosProfissionais.ufConselho = solicitacao.id_uf_conselho_trabalho || null;
    solicitacaoFormatada.dadosProfissionais.tipoConselho = solicitacao.id_conselho_trabalho || null;
    solicitacaoFormatada.dadosProfissionais.numeroConselho = solicitacao.numero_conselho || '';

    // participacao ===========================
    solicitacaoFormatada.participacao.participacoes = [];
    if (solicitacao.participacao_mais_medicos) {
        solicitacaoFormatada.participacao.participacoes.push({
            sigla: TIPOS_PARTICIPACOES[0].sigla,
            id: TIPOS_PARTICIPACOES[0].id,
            dataInicial: solicitacao.data_inicio_participacao_mais_medicos
        });
    }
    if (solicitacao.participacao_provab) {
        solicitacaoFormatada.participacao.participacoes.push({
            sigla: TIPOS_PARTICIPACOES[1].sigla,
            id: TIPOS_PARTICIPACOES[1].id,
            dataInicial: solicitacao.data_inicio_participacao_provab
        });
    }
    if (solicitacao.residencia) {
        solicitacaoFormatada.participacao.participacoes.push({
            sigla: TIPOS_PARTICIPACOES[2].sigla,
            id: TIPOS_PARTICIPACOES[2].id,
            dataInicial: solicitacao.data_inicio_residencia
        });
    }

    // vinculo ===========================
    solicitacaoFormatada.vinculo.instituicoes = [];
    solicitacaoFormatada.vinculo.equipes = [];
    if (solicitacao.equipes && solicitacao.equipes.length > 0) {
        solicitacao.equipes.forEach(e => {
            if (e.codigo_ine_equipe_saude_familia || e.id_equipe_saude_familia) {
                solicitacaoFormatada.vinculo.equipes.push({
                    instituicao: e.nome_instituicao,
                    id_instituicao: e.id_instituicao,
                    cnesInstituicao: e.cnes_instituicao,
                    nome: e.nome_equipe_saude_familia,
                    ine: e.codigo_ine_equipe_saude_familia,
                    id: e.id_equipe_saude_familia || null
                });
            }

            if (solicitacaoFormatada.vinculo.instituicoes.filter(i => i.id === e.id_instituicao).length === 0) {
                solicitacaoFormatada.vinculo.instituicoes.push({
                    nome: e.nome_instituicao,
                    id: e.id_instituicao,
                    cnes: e.cnes_instituicao,
                    municipio: e.nome_cidade_instituicao,
                    estado: e.sigla_estado_instituicao,
                    origem_instituicao: e.origem_instituicao
                });
            }
        });
    }
    // solicitacaoFormatada.vinculo.acompanharFacebook = (solicitacao.acompanhar_facebook === 'S') || false;
    solicitacaoFormatada.autorizacao.autorizarDivulgacao = solicitacao.receber_divulgacao_autorizada ?? true;
    solicitacaoFormatada.autorizacao.divulgacaoEmail = solicitacao.receber_divulgacao_email ?? true;
    solicitacaoFormatada.autorizacao.termoUsoAceito = solicitacao.termo_uso_aceito ?? false;

    if (!global.gConfig.termo_autorizacao_solicitacao) {
        solicitacaoFormatada.autorizacao.autorizarDivulgacao = false;
        solicitacaoFormatada.autorizacao.divulgacaoEmail = false;
        solicitacaoFormatada.autorizacao.termoUsoAceito = true;
    }
    return solicitacaoFormatada;
}

export default { formatarParaSolicitacao };