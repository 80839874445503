import ConstantesCadastroFuncionario from '@constantes/cadastro/funcionario';

const { REDUCER_TYPE } = ConstantesCadastroFuncionario;

const INITIAL_STATE = {
    parametrosPesquisaInstituicao: {
        nome: '',
        cnes: '',
        estado: null,
        municipio: null,
        instituicoes_servico: false
    },
    pesquisarInstituicao: false,
    idFuncionarioAlteracao: null,
    dadosFuncionarioAlteracao: {
        nome: '',
        login: '',
        email: '',
        usuarios: null
    },
    instituicoesAdminLocal: []
};

function cadastroFuncionarioReducer(state, action) {
    if(!state) {
        state = INITIAL_STATE;
    }
    
    const { type, payload } = action;

    if(type === REDUCER_TYPE.LIMPAR_PARAMETROS_PESQUISA_INSTITUICAO){
        return {
            ...state,
            parametrosPesquisaInstituicao: INITIAL_STATE.parametrosPesquisaInstituicao
        };
    }

    if(type === REDUCER_TYPE.SETAR_PARAMETROS_PESQUISA_INSTITUICAO){
        return {
            ...state,
            parametrosPesquisaInstituicao: payload.parametros
        };
    }

    if(type === REDUCER_TYPE.PESQUISAR_INSTITUICAO) {
        return {
            ...state,
            pesquisarInstituicao: payload.pesquisarInstituicao
        };
    }

    if(type === REDUCER_TYPE.SETAR_ID_FUNCIONARIO_ALTERACAO) {
        return {
            ...state,
            idFuncionarioAlteracao: payload.idFuncionario
        };
    }

    if(type === REDUCER_TYPE.SETAR_DADOS_FUNCIONARIO_ALTERACAO) {
        
        return {
            ...state,
            dadosFuncionarioAlteracao: payload.dadosFuncionario
        };
    }

    if(type === REDUCER_TYPE.SETAR_INSTITUICOES_ADMIN_LOCAL) {
        return {
            ...state,
            instituicoesAdminLocal: payload.instituicoes
        };
    }
    return state;
}

export default cadastroFuncionarioReducer;