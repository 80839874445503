import React, { useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import {
    SttBox,
    SttHeading,
    SttText
} from '@stt-componentes/core';
import ConfirmacaoMensagem from './confirmacaoMensagem';
import translate from "@componentes/translate";
import BuscaCpf from './buscaCpf';
import ContraSenha from './contraSenha';
import TermoConsentimento from './termoConsentimento';
import { useQuery } from '../../../../hooks';

const useStyles = makeStyles(theme => ({
    formColumn: {
        display: "flex",
        alignItems: "center",
        flexDirection: 'column',
        [theme.breakpoints.up('xs')]: {
            padding: theme.spacing(0.5, 2.5, 0.5, 2.5),
        },
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(1.5, 4.5, 1.5, 4.5),
        },
    },
    heading: {
        marginBottom: '30px'
    },
    text: {
        marginBottom: '20px'
    },
    buttons: {
        marginTop: '20px'
    },
    errorText: {
        marginTop: '-2px',
    },
    form: {
        marginTop: '20px',
        width: '80%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'space-between',
    }
}));

const SITUACAO_CADASTRO = {
    AVISO_MENSAGEM: 'AVISO_MENSAGEM',
    BUSCA_CPF: 'BUSCA_CPF',
    CONTRA_SENHA: 'CONTRA_SENHA',
    TCLE: 'TCLE',
}

const switchSituacao = (situacaoTela, setSituacaoTela, cpfPacientePadrao, classes) => {
    switch (situacaoTela) {
        case SITUACAO_CADASTRO.AVISO_MENSAGEM:
            return (
                <ConfirmacaoMensagem
                    classes={classes}
                    setSituacao={() => setSituacaoTela(SITUACAO_CADASTRO.BUSCA_CPF)}
                />
            );
        case SITUACAO_CADASTRO.BUSCA_CPF:
            return (
                <BuscaCpf
                    classes={classes}
                    setSituacaoContraSenha={() => setSituacaoTela(SITUACAO_CADASTRO.CONTRA_SENHA)}
                    cpfPacientePadrao={cpfPacientePadrao}
                />
            );
        default:
            return null;
    }
}

const Aviso = ({ strings, layout }) => {
    const classes = useStyles();

    const [situacaoTela, setSituacaoTela] = useState(SITUACAO_CADASTRO.AVISO_MENSAGEM);
    const [nomeInstancia, setNomeInstancia] = useState(global.gConfig.instancia_nome);
    const [cpfPacientePadrao, setCpfPacientePadrao] = useState(null);
    const query = useQuery();
    const cpfPaciente = query.get('cpf') || null;

    useEffect(() => {
        if (global.gConfig.instancia_nome_alternativos[layout]) {
            setNomeInstancia(global.gConfig.instancia_nome_alternativos[layout]);
        }

        if (cpfPaciente) {
            setSituacaoTela(SITUACAO_CADASTRO.BUSCA_CPF);
            setCpfPacientePadrao(cpfPaciente);
        }
    }, [layout]);

    return (
        <SttBox className={classes.formColumn}>
            {
                situacaoTela === SITUACAO_CADASTRO.CONTRA_SENHA
                    ?
                    <ContraSenha classes={classes} setSituacao={() => setSituacaoTela(SITUACAO_CADASTRO.TCLE)} />
                    :
                    <>
                        <SttHeading variant="h2" color="primary" className={classes.heading}>
                            {strings.solicitacaoPaciente}
                        </SttHeading>
                        <SttText className={classes.text}>
                            {strings.texto1Paciente}
                        </SttText>
                        <SttText className={classes.text}>
                            {strings.textoAcessoCadsus}
                        </SttText>
                        <SttText className={classes.text}>
                            {strings.texto4Paciente(nomeInstancia, true)}
                        </SttText>
                        {
                            switchSituacao(situacaoTela, setSituacaoTela, cpfPacientePadrao, classes)
                        }
                    </>
            }
            {
                situacaoTela === SITUACAO_CADASTRO.TCLE &&
                <TermoConsentimento layout={layout} />
            }
        </SttBox>
    );
}

export default translate('SolicitacaoAviso')(Aviso);