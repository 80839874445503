import ConstantesSolicitacaoCadastro from '@constantes/solicitacao-cadastro';

const { REDUCER_TYPE, SITUACAO } = ConstantesSolicitacaoCadastro;

const INITIAL_STATE = {
    aviso: {
        checkEntendi: false,
        situacaoSolicitacao: SITUACAO.AVISO_MENSAGEM,
        usuarios: null,
        cpf: null,
    }
};

function solicitacaoCadastroReducer(state, action) {
    if (!state) {
        state = INITIAL_STATE;
    }

    const { type } = action;

    if (type === REDUCER_TYPE.MARCAR_ENTENDI_AVISO) {
        const { checkEntendi } = action;
        state.aviso.checkEntendi = checkEntendi;
        const aviso = Object.assign({}, state.aviso);
        return {
            ...state,
            aviso
        };
    }
    if (type === REDUCER_TYPE.CONFIRMAR_ENTENDI_AVISO) {
        const { situacaoSolicitacao, dadosSolicitacao } = action;
        state.aviso.situacaoSolicitacao = situacaoSolicitacao;
        state.aviso.dadosSolicitacao = dadosSolicitacao;
        const aviso = Object.assign({}, state.aviso);
        return {
            ...state,
            aviso
        };
    }
    if (type === REDUCER_TYPE.SELECIONAR_USUARIO_SOLICITACAO_AVISO) {
        const { situacaoSolicitacao, usuarios, } = action;
        state.aviso.situacaoSolicitacao = situacaoSolicitacao;
        state.aviso.usuarios = usuarios;
        const aviso = Object.assign({}, state.aviso);
        return {
            ...state,
            aviso
        };
    }
    if (type === REDUCER_TYPE.ABRIR_SOLICITACAO_CADASTRO) {
        const { situacaoSolicitacao, cpf, dadosSolicitacao, dadosCadsusCnes } = action;
        state.aviso.dadosCadsusCnes = dadosCadsusCnes;
        state.aviso.situacaoSolicitacao = situacaoSolicitacao;
        state.aviso.cpf = cpf;
        state.aviso.dadosSolicitacao = dadosSolicitacao;
        const aviso = Object.assign({}, state.aviso);

        return {
            ...state,
            aviso
        };
    }
    return state;
}

export default solicitacaoCadastroReducer;